import React, {useState, useReducer} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Box,
  Container,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Timeline from '@proofpoint/fuse-ui/components/Timeline';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import Alert from '@proofpoint/fuse-ui/components/Alert';
import Button from '@proofpoint/fuse-ui/components/Button';
import IconButton from '@proofpoint/fuse-ui/components/IconButton';
import LinearProgress from '@proofpoint/fuse-ui/components/LinearProgress';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useResults, initResultsState, processIpDetail, resultsReducer } from '../context/ResultsProvider';
import {IP} from '../Input';
import useApiResult from '../useApiResult';
import { TRow } from './AlgorithmResult'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: '1',
    },
  },
  borderless: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  rowHeader: {
    fontSize: 18,
  },
  linear: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
  },
  warningIcon: {
    color: '#ef6c00'
  },
  okIcon: {
    color: '#2e784e'
  },
  detail: {
    display: 'flex',
    '& > *': {
      flex: '1 1 auto'
    }
  },
});

const IpAnalysis = ({ip_address}) => {
  const [state] = useResults();
  return <IpRow ip_address={ip_address} hide={false} state={state}/>
}

export const IpRow = ({ip_address, hide, state}) => {
  if (state.loading) return (<TableRow hover>
      <TableCell style={{minWidth:20}}></TableCell>
      <TableCell style={{minWidth:20}}></TableCell>
      <TableCell>{ip_address}</TableCell>
      <TableCell>
        <LinearProgress/>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>);
  if (state.error) {
    let error_message;
    try {
      const jr = JSON.parse(state.error);
      error_message  = (Object.hasOwn(jr, 'message')) ? jr.message : jr.stringify();
    } catch (e) {
      error_message = state.error;
      console.error(e);
    }
    return (<TableRow hover>
      <TableCell style={{minWidth:20}}></TableCell>
      <TableCell style={{minWidth:20}}><ErrorIcon color="error"/></TableCell>
      <TableCell>{ip_address}</TableCell>
      <TableCell colSpan={2}>{error_message}</TableCell>
    </TableRow>);
  }
  if (state.data == null) return (<TableRow hover>
      <TableCell style={{minWidth:20}}></TableCell>
      <TableCell style={{minWidth:20}}><WarningIcon/></TableCell>
      <TableCell>{ip_address}</TableCell>
      <TableCell colSpan={2}>No data</TableCell>
    </TableRow>);
  let result = state.data.find(result => result.ipAddress===ip_address)
  if (result !== undefined) {
    return (<IpResult result={result} hide={hide} />);
  }
  return (<TableRow hover>
    <TableCell style={{minWidth:20}}></TableCell>
    <TableCell style={{minWidth:20}}></TableCell>
    <TableCell>{ip_address}</TableCell>
    <TableCell colSpan={2}>Result not found in response</TableCell>
  </TableRow>);
  
}

const IpResult = ({result, hide}) => {
  if (result.score !== "127.0.0.0") {
    return <InfoView result={result}/>;
  }
  if (hide) return null;
  return <UnpublishedView result={result}/>;
}

const InfoView = ({result}) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [detailResult, dispatch] = useReducer(resultsReducer, initResultsState);
  useApiResult(detailResult.request, dispatch);
  const ip = new IP(result.ipAddress) 
  const detail = () => {
    setOpen(!open)
    dispatch(processIpDetail(ip))
  };
  let level, icon;
  switch (result.score) {
    case "127.0.0.3":
      level = "inherit";
      icon = <WarningIcon className={classes.warningIcon} color="inherit"/>
      break;
    case "127.0.0.4":
      level = "inherit";
      icon = <CheckCircleIcon className={classes.okIcon} color="inherit" />
      break;
    default:
      level = "error";
      icon = <ErrorIcon color="error" />
      break;
  }


  return (<React.Fragment>
      <TableRow hover className={classes.borderless} onClick={detail}>
        <TableCell style={{minWidth:20}}>
          <IconButton aria-label="expand row" size="small" onClick={detail}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{minWidth:20}}>{icon}</TableCell>
        <TableCell>
          <Typography variant="button" color={level}>{result.ipAddress}</Typography>
        </TableCell>
        <TableCell>
          <Reputation ip={result}/>
        </TableCell>
        <TableCell><RemediateAction ip={result}/></TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <DetailView result={detailResult} style={{display:'flex'}}/>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </React.Fragment>
  );

}

const UnpublishedView = ({result}) => {
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow hover className={classes.root}>
        <TableCell style={{minWidth:20}}></TableCell>
        <TableCell style={{minWidth:20}}><HelpOutlineIcon color="primary" /></TableCell>
        <TableCell><Typography variant="button">{result.ipAddress}</Typography></TableCell>
        <TableCell><Reputation ip={result}/></TableCell>
        <TableCell><RemediateAction ip={result}/></TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const DetailView = ({result}) => {
  const classes = useRowStyles();
  if (result.loading) return (<Paper className={classes.linear}><LinearProgress/></Paper>);
  if (result.error) return <Alert severity={'error'}>{result.error}</Alert>;
  if (result.data === null) return null;
  const formatDate = (d) => {
    if (d == null || d == "") return "Not available";
    const out =  new Date(d);
    return out.toDateString();
  }
  const formatTime = (d) => {
    if (d == null || d == "") return "--";
    const out =  new Date(d);
    return out.toTimeString();
  }
  let id = 0;
  let activities = [];
  activities.push(
    {
      id: id++,
      date: formatDate(result.data.dateFirstSeen),
      time: formatTime(result.data.dateFirstSeen),
      icon: <VisibilityIcon />,
      description: "First Seen"
    }
  );
  if (result.data.recentFeedbackDetails.firstBlock) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.data.recentFeedbackDetails.firstBlock),
        time: formatTime(result.data.recentFeedbackDetails.firstBlock),
        icon: <FingerprintIcon />,
        description: "First report"
      }
    );
  }
  if (result.data.dateBecameSpammy) {
    let identified = "";
    switch (true) {
      case result.data.category.knownSpam: identified = "Identified as spam"; break;
      case result.data.category.knownPhishing: identified = "Identified as phishing"; break;
      case result.data.category.knownVirus: identified = "Identified as virus"; break;
      case result.data.category.knownCompromised: identified = "Identified as compromised"; break;
    }
    activities.push(
      {
        id: id++,
        date: formatDate(result.data.dateBecameSpammy),
        time: formatTime(result.data.dateBecameSpammy),
        icon: <CloseIcon />,
        description: identified
      }
    );
  }
  if (result.data.recentFeedbackDetails.lastBlock) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.data.recentFeedbackDetails.lastBlock),
        time: formatTime(result.data.recentFeedbackDetails.lastBlock),
        icon: <FingerprintIcon />,
        description: "Last report"
      }
    );
  }
  if (result.data.dateBecameLegit) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.data.dateBecameLegit),
        time: formatTime(result.data.dateBecameLegit),
        icon: <CheckCircleIcon />,
        description: "Became legitimate"
      }
    );
  }
  if (result.data.dateLastSeen) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.data.dateLastSeen),
        time: formatTime(result.data.dateLastSeen),
        icon: <VisibilityIcon />,
        description: "Last seen"
      }
    );
  }
  activities = activities.map(activity => {
    return {
      ...activity,
      description: <Typography variant="subtitle1">{activity.description}</Typography>,
      subtitle: <Typography variant="body1">{activity.subtitle}</Typography>,
      content: <Typography variant="caption">{activity.subtitle}</Typography>
    }
  });

  return (
    <React.Fragment>
    <Container className={classes.detail}>
      <Timeline activities={activities} />
      <Table size="small" aria-label="Threat Analysis" >
        <TableBody>
          <TRow label="Active In Authority" data={result.data.activeThreat && (<span>Yes</span>)||(<span>No</span>)} />
          <TRow label="Feedback Volume" data={result.data.recentFeedbackDetails.feedbackVolume} />
          <TRow label="% Of Reports Were Complaints" data={result.data.recentFeedbackDetails.percentageBlocks} percent={true} />
          <TRow label="% Of Reports Were Spam Traps" data={result.data.recentFeedbackDetails.percentageBlocksSpamTraps} percent={true} />
          <TRow label="# Service Providers Seeing Spam Trap Feedback" data={result.data.recentFeedbackDetails.orgCountSpamTraps} />
          <TRow label="Domain" data={result.data.host} skipEmpty={true} />
          <TRow label="Suspect Reason" data={result.data.suspectReason} skipEmpty={true} />
        </TableBody>
      </Table>
    </Container>
    </React.Fragment>
  );
}

const RemediateAction = ({ip}) => {
  if (ip.score !== "127.0.0.2") {
    return null;
  }
  const href = `http://csi.cloudmark.com/reset-request/?ip=${ip.ipAddress}`;
  const openLink = () => {
     window.open(href, '_blank', 'noopener,noreferrer')
  }
  return (
    <Button size="small" icon={<RefreshIcon />} onClick={openLink} iconPosition="left" >
      Remediation Request
    </Button>
  );
}

const Reputation = ({ip}) => {
  switch (ip.score) {
    case "127.0.0.2": // Poor 
      return <Typography variant="subtitle1">Cloudmark is currently publishing the reputation of this IP address as <strong>poor</strong></Typography>;
    case "127.0.0.4":  // Good
      return <Typography variant="subtitle1">Cloudmark is currently categorizing this IP address as Known Mail Forwarder</Typography>;
    case "127.0.0.3": // Suspect
      return <Typography variant="subtitle1">Cloudmark is currently publishing the reputation of this IP address as <strong>suspect</strong></Typography>;
    default:
      return <Typography variant="subtitle1">Cloudmark is not currently publishing reputation for this IP address</Typography>;
  }
}

export default IpAnalysis;

