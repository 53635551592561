import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as jose from 'jose'

export const useScopes = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [ scopes, setScopes ] = useState({scopes:"", error: null, done:false});

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      let token;
      try {
        token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE,
        });
        const claims = jose.decodeJwt(token)
        if (claims.scope) {
          setScopes({...scopes, scopes: claims.scope, done: true});
        } else {
          setScopes({...scopes, done: true});
        }

      } catch (e) {
        setScopes({...scopes, error:e, done: true});
        return;
      }
    })();

  }, [getAccessTokenSilently, isAuthenticated]);

  return [scopes.scopes, scopes.error, scopes.done]

};

export default useScopes;