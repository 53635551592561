import React from 'react';
import { getIPDetail, getCsiIP, getCsiCidr, getCartridgeVersion, postAuthorityAnalysis, postMessageAnalysis } from '../requests';

const Results            = React.createContext();
const PROCESS_CARTRIDGES = "APP/ACTION/PROCESS_CARTRIDGES";
const PROCESS_VERSION    = "APP/ACTION/PROCESS_VERSION";
const INIT_CIDR          = "APP/ACTION/INIT_CIDR";
const PROCESS_CSI_CIDR   = "APP/ACTION/PROCESS_CSI_CIDR";
const PROCESS_CSI_IP     = "APP/ACTION/PROCESS_CSI_IP";
const PROCESS_IP_DETAIL  = "APP/ACTION/PROCESS_IP";
const PROCESS_ANALYSIS   = "APP/ACTION/PROCESS_ANALYSIS";
const PROCESS_MESSAGE    = "APP/ACTION/PROCESS_MESSAGE";
const SELECT_CARTRIDGE   = "APP/ACTION/SELECT_CARTRIDGE";
const SHOW               = "APP/ACTION/SHOW";
const HIDE               = "APP/ACTION/HIDE";
const TOGGLE_SHOW        = "APP/ACTION/TOGGLE_SHOW";
const REQ_AUTHN          = "APP/ACTION/AUTHN";
const REQ_LOADING        = "APP/ACTION/LOADING";
const REQ_COMPLETE       = "APP/ACTION/COMPLETE";
const REQ_BATCH          = "APP/ACTION/BATCH";
const REQ_ERROR          = "APP/ACTION/ERROR";
const RESET              = "APP/ACTION/RESET";
const AUTHN_ERROR        = "APP/ACTION/AUTHN_ERROR";
const SET_INPUT          = "APP/ACTION/SET_INPUT";

export const processCsiCidr       = (cidr) =>               ({type: PROCESS_CSI_CIDR, cidr});
export const processCsiIp         = (ip) =>                 ({type: PROCESS_CSI_IP, ip});
export const processCartridges    = () =>                   ({type: PROCESS_CARTRIDGES});
export const processVersion       = (cv) =>                 ({type: PROCESS_VERSION, cv});
export const processIpDetail      = (ip) =>                 ({type: PROCESS_IP_DETAIL, ip});
export const processAnalysis      = (analysis) =>           ({type: PROCESS_ANALYSIS, analysis});
export const processMessage       = (message, cartridge) => ({type: PROCESS_MESSAGE, message, cartridge});
export const showResults          = () =>                   ({type: SHOW});
export const hideResults          = () =>                   ({type: HIDE});
export const toggleShow           = () =>                   ({type: TOGGLE_SHOW});
export const setSelectedCartridge = (cv) =>                 ({type: SELECT_CARTRIDGE, cv});
export const authenticating       = () =>                   ({type: REQ_AUTHN});
export const loading              = () =>                   ({type: REQ_LOADING});
export const complete             = (id, data) =>           ({type: REQ_COMPLETE, data, id});
export const completeBatch        = (results) =>            ({type: REQ_BATCH, results});
export const error                = (id, error) =>          ({type: REQ_ERROR, error, id});
export const auth_error           = (error) =>              ({type: AUTHN_ERROR, error});
export const setInput             = (input) =>              ({type: SET_INPUT, input});
export const reset                = () =>                   ({type: RESET});
export const initResultsState = {
  show: false,
  done: false,
  loading: false,
  input: null,
  error: null,
  data :null,
  request: null
};

export const resultsReducer = (state = initResultsState, action) => {
  switch (action.type) {
    case PROCESS_VERSION:
      return {
        ...state,
        data: null,
        request: getCartridgeVersion(action.cv),
        error: null,
        done: false
      };
    case PROCESS_CSI_CIDR:
      return {
        ...state,
        request: getCsiCidr(action.cidr),
        data: null,
        done: false,
        show: true,
        error: null,
        input: action.cidr
      };
    case PROCESS_CSI_IP:
      return {
        ...state,
        data: null,
        request: getCsiIP(action.ip),
        error: null,
        done: false,
        input: action.ip,
        show: true
      };
    case PROCESS_IP_DETAIL:
      return {
        ...state,
        data: null,
        request: getIPDetail(action.ip),
        error: null,
        done: false,
        input: action.ip,
        show: true
      };
    case PROCESS_ANALYSIS:
      return {
        ...state,
        data: null,
        request: postAuthorityAnalysis(action.analysis),
        error: null,
        input: action.analysis,
        done: false,
        show: true
      };
    case PROCESS_MESSAGE:
      return {
        ...state,
        data: null,
        request: postMessageAnalysis(action.message, action.cartridge),
        error: null,
        input: action.message,
        done: false,
        show: true
      };
    case SELECT_CARTRIDGE:
      return {
        ...state,
        selectedCartridge: action.cv,
      };
    case SHOW:
      return {
        ...state,
        show: true,
      };
    case HIDE:
      return {
        ...state,
        show: false,
      };
    case TOGGLE_SHOW:
      return {
        ...state,
        show: !state.show,
      };
    case REQ_AUTHN:
    case REQ_LOADING:
      return {
        ...state,
        loading: true
      };
    case REQ_ERROR:
      if (action.error.includes("upstream") || 
          action.error.includes("NetworkError") ||
          action.error.includes("Failed to fetch")) {
        state.error = `An unexpected error is preventing Customer Accuracy Tools (CAT) from processing your
        request. Please reload the page and retry this action. If you continue to experience 
        an issue, take a screenshot of this error message and then raise a ticket with the 
        Cloudmark Support team, attach screenshot to review the error and process your original 
        request. Sorry for the inconvenience. (Err ref: ${action.error})`;
      } else {
        state.error = action.error;
      }
      // Fall through into REQ_COMPLETE
    case REQ_COMPLETE:
      return {
        ...state,
        data: action.data,
        done: true,
        loading: false
      };
    case AUTHN_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        done: true
      };
    case SET_INPUT:
      return {
        ...state,
        done: false,
        loading: false,
        data: null,
        input: action.input,
        request: null
      };
    case RESET:
      return {...initResultsState};
  }
};

export const useResults = () => React.useContext(Results);

export const ResultsProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);
  return (
    <Results.Provider value={[globalState, dispatch]}>{children}</Results.Provider>
  );
};


