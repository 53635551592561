class Mail {
  #headers = {};
  #boundary;
  #attachments = [];
  #fileReader;
  #resolve;
  #reject;
  #CRLF = "\r\n"; 
  #MAX = 78;
  #preamble = "This is a multipart message. If you're reading this, you" + this.#CRLF +
  "might want to consider changing to a mail reader that understands" + this.#CRLF +
  "how to properly display multipart messages." + this.#CRLF;


  constructor() {
    this.#boundary = this.uuid();
    this.setHeader("Date", (new Date).toUTCString());
    this.setHeader("Subject", "No subject");
    if (typeof FileReader != "undefined") {
      this.#fileReader = new FileReader();
      this.#fileReader.onload = this.onload(this);
      this.#fileReader.onerror = this.onerror(this);
    }
  }

  onload(self) {
    return function() {
      let str = self.#fileReader.result;
      self.#resolve(str.substring(str.indexOf(",")+1));
    }
  }

  onerror(self) {
    return function() {
      let err = self.#fileReader.error;
      self.#reject(err);
    }
  }

  readData(str) {
    const encoder = new TextEncoder();
    const blob = new Blob([encoder.encode(str)]);
    this.#fileReader.readAsDataURL(blob);
  }

  base64(str) {
    return new Promise((resolve, reject) => {
      this.#resolve = resolve;
      this.#reject = reject;
      this.readData(str);
    });
  }
 
  asciiEncode(str) {
    let newstr = "";
    for (let i = 0; i < str.length; i++) {
      if (str.codePointAt(i) < 32) continue; //control char
      if (str.codePointAt(i) < 127) newstr += str[i];
      else {
        let char = `U+${str.codePointAt(i)};`;
        newstr += char.replace('U+', '&#');
      }
    }
    return newstr;
  }
 
  setHeader(key, value) {
    this.#headers[this.normalize(key)] = this.asciiEncode(value);
    return this;
  }

  getHeader(key) {
    return this.#headers[this.normalize(key)];
  }

  appendAttachment(attachment) {
    this.#attachments[this.#attachments.length] = attachment
  }

  // normalize - Normalizes header keys such that x-custom-header becomes X-Custom-Header
  normalize(key) {
    let normalized = "";
    for(let i = 0; i < key.length; i++) {
      if (i == 0 || key[i-1] == "-") {
        normalized += key[i].toUpperCase();
      } else {
        normalized += key[i].toLowerCase();
      }
    }
    return normalized; 
  }

  // wrap will fold a string at $max characters. It will attempt to fold at the 
  // nearest space before $max. If there are no spaces (e.g. base64) folding 
  // happens at $max.
  wrap(str, max) {
    // We want to remove any lone \r or \n characters and only have #CRLFs in the text
    // Do this by removing all \r chars then replacing remaining \n with #CRLF
    str = str.replace(/\r/g, "").replace(/\n/g, this.#CRLF);
    let lines = [];
    for (const line of str.split(this.#CRLF)) {
      if (line.length <= max) {
        lines.push(line);
        continue;
      }
      // any spaces?
      if (line.search(/\s/) > 0) {
        lines.push(...this.wrapText(line, max));
        continue;
      }
      // no spaces or tabs, just fold at max
      lines.push(...this.wrapString(line, max));
      
    }
    return lines.join(this.#CRLF);
  }

  wrapString(str, max) {
   // Ensure max is a multiple of 4 for base64
   max = max - (max % 4);
   let parts = [];
   for (let i = 0; i < str.length; i += max) {
     parts.push(str.substring(i, i + max));
   }
   return parts;
 }
 
  wrapText(str, max) {
    // Split the input string into words and whitespace
    if (max < 1) {
      throw new Error('Invalid max length');
    }
    const tokens = str.split(/(\s+)/).filter(t => t.length > 0);
    let lines = [];
    let currentLine = '';

    tokens.forEach(token => {
      if (token.length > max) {
        // If the token itself exceeds max, split it and treat each part as a separate token
        const parts = token.match(new RegExp('.{1,' + max + '}', 'g'));
        parts.forEach((part, index) => {
          if (currentLine.length > 0) {
            lines.push(currentLine);
            currentLine = part;
          } else {
            currentLine = part;
          }
        });
      } else if (currentLine.length + token.length <= max) {
        // If the token fits in the current line, add it
        currentLine += token;
      } else {
        // If the token doesn't fit, start a new line
        lines.push(currentLine);
        currentLine = token;
      }
    });

    // Add the last line if it's not empty
    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    return lines;
  }

  fold(line) {
    line = line.trim();
    if (line.length < this.#MAX) {
      return line;
    }
  
    let folded = "";
    let folded_length = 0;
    line = line.replace(/[\v\s]/gm, " "); // normalize whitespace
    for (const word of line.split(' ')) {
      if ((folded.length - folded_length + word.length) > this.#MAX) {
        folded_length = folded.length;
        folded += this.#CRLF + " " + word;
      } else if (folded == "") {
        folded += word;
      } else {
        folded += " " + word;
      }
    }
    return folded.trim();
  }

  uuid() {
    try {
      return window.crypto.randomUUID();
    } catch (e) {
      console.log(`No client support for crypto function: ${e}`);
      const generateRandomChar = () => {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        return chars.charAt(Math.floor(Math.random() * chars.length));
      };
      let uuid = "";
      for (let i = 0; i < 36; i++) {
        if ([8, 13, 18, 23].includes(i)) {
          uuid += "-";
        } else {
          uuid += generateRandomChar();
        }
      }
      return uuid;
    }
  }

  getHeadersArray() {
    if (this.#attachments.length > 0) {
      this.setHeader("Content-Type", `multipart/mixed; boundary=${this.#boundary}`) 
    } else {
      this.setHeader("Content-Type", 'text/plain; charset="utf-8"');
      this.setHeader("Content-transfer-encoding", "base64");
    }
    let out = [];
    for (let header of Object.keys(this.#headers)) {
      let line = this.writeHeader(header, this.#headers[header]);
      line = this.fold(line);
      out[out.length] = line;
    }
    return out;
  }

  writeHeaders() {
    return this.getHeadersArray().join(this.#CRLF);
  }

  writeHeader(key, value) {
    return `${this.normalize(key)}: ${value}${this.#CRLF}`
  }

  createBoundary(id) {
    return `${this.#CRLF}--${id}${this.#CRLF}`;
  }

  closeBoundary(id) {
    return `${this.#CRLF}--${id}--${this.#CRLF}`;
  }

  writeBody(b64Body) {
    let out = [];
    if (this.#attachments.length > 0) {
      const inner = this.uuid();
      out.push(this.#preamble + this.#CRLF);
      out.push(this.createBoundary(this.#boundary));
      out.push(this.writeHeader("Content-Type", "text/plain; charset=utf-8"));
      out.push(this.writeHeader("Content-Disposition","inline"));
      out.push(this.writeHeader("Content-Description", "support message"));
      out.push(this.writeHeader("Content-transfer-encoding", "base64"));
      out.push(this.#CRLF);
      out.push(this.wrap(b64Body, 76));
      out.push(this.#CRLF);
      out.push(this.createBoundary(this.#boundary));
      out.push(this.writeHeader("Content-Type", `multipart/mixed; boundary=${inner}`));
      out.push(this.writeHeader("Content-Disposition","attachment"));
      out.push(this.writeHeader("Content-Description", "multipart"));
      out.push(this.#CRLF);
      this.#attachments.forEach((attachment) => {
        out.push(this.createBoundary(inner));
        out.push(this.writeHeader("Content-Type", "text/plain"));
        out.push(this.writeHeader("Content-Disposition","attachment"));
        out.push(this.writeHeader("Content-Description", attachment.name));
        out.push(this.writeHeader("Content-transfer-encoding", "base64"));
        out.push(this.#CRLF);
        out.push(this.wrap(attachment.base64, 76));
        out.push(this.#CRLF);
      });
      out.push(this.closeBoundary(inner));
      out.push(this.closeBoundary(this.#boundary));
    } else {
      out.push(this.wrap(b64Body, 76));
    } 
    return out.join("");
  }

  writeMessage(body) {
    return `${this.writeHeaders()}${this.#CRLF}${this.writeBody(body)}`;
  }
}

//module.exports = Mail; // uncomment this line and comment out the next to run jest tests
export {Mail as default};
