import React, { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { IP, CIDR, AnalysisHeader, Message, MsgFile } from '../Input';
import IpAnalysis from './IpAnalysis';
import CidrAnalysis from './CidrAnalysis';
import MessageAnalysis from './MessageAnalysis';
import SupportForm, { SupportButton } from './SupportForm';
import { AssertButton } from './Assert';
import Flash from './Flash';
import { useResults, hideResults } from '../context/ResultsProvider';
import { useSupport, showSupport } from '../context/SupportProvider';
import useApiResult from '../useApiResult';
import IconButton from '@proofpoint/fuse-ui/components/IconButton';
import Badge from '@proofpoint/fuse-ui/components/Badge';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import FormControlLabel from '@proofpoint/fuse-ui/components/FormControlLabel';
import Switch from '@proofpoint/fuse-ui/components/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 20,
  },
    backdrop: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    height: '95%',
    padding: '3rem',
    zIndex: '9999',
  },
  rowHeader: {
    fontSize: 20,
    color: "#37474f",
    lineHeight: "20px",
  },
  pos: {
    marginBottom: 12,
  },
  counter: {
    padding: 6,
    fontSize: 16,
  },
  counterError: {
    backgroundColor: 'rgba(183, 28, 28, 0.08)',
  },
  counterWarning: {
    backgroundColor: '#fff5db',
  },
  counterPrimary: {
    backgroundColor: 'rgba(129, 212, 250, 0.24)',
  },
  counterOK: {
    backgroundColor: 'rgba(165, 214, 167, 0.24)',
  },
  switchBase: {
    '& > span': {
      top: '-3px',
      left: '-2px',
    },
  },
  summary: {
    marginTop: 15,
    marginBottom: 30,
  },
});

const Results = () => {
  const [results, dispatch] = useResults();
  const input = results.input;
  const [hide, setHide] = useState(true);
  useApiResult(results.request, dispatch);  
  
  switch (true) {
    case input instanceof IP:
      return (
        <Result>
          <IpResult>
            <IpAnalysis ip_address={input.normalize()} />
          </IpResult>
        </Result>
      );
    case input instanceof CIDR:
      return (
        <Result>
          <Summary />
          <IpResult hide={hide} setHide={setHide}>
            <CidrAnalysis hide={hide}  />
          </IpResult>
        </Result>
      );
    case input instanceof Message:
    case input instanceof MsgFile:
    case input instanceof AnalysisHeader:
      return (
        <Result>
          <MessageAnalysis />
        </Result>
      );
    default:
      return null;
  }
}

const Summary = () => {
  const classes = useStyles();
  const [results] = useResults();
  let poor, suspect, good, unknown 
  poor = suspect = good = unknown = "0";
  if (results.data) {;
    for (const result of results.data) {
      switch (result.score) {
        case "127.0.0.2":
          poor++;
          break
        case "127.0.0.3":
          suspect++;
          break
        case "127.0.0.4":
          good++;
          break
        default:
          unknown++;
          break
      }
    }
  }
  return (
      <Container className={classes.summary}>
        <Typography variant="h5" component="h5">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <Paper className={[classes.counter, classes.counterError].join(' ')} color="textSecondary">
                <Badge label={poor.toString()} status="error" /> Poor
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={[classes.counter, classes.counterWarning].join(' ')} color="textSecondary">
                <Badge label={suspect.toString()} status="warning" /> Suspect
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={[classes.counter, classes.counterPrimary].join(' ')} color="textSecondary">
                <Badge label={unknown.toString()} status="primary" /> Unlisted
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={[classes.counter, classes.counterOK].join(' ')} color="textSecondary">
                <Badge label={good.toString()} status="ok" /> Mail Forwarder
              </Paper>
            </Grid>
          </Grid>
        </Typography>
      </Container>
  );
}

const Result = ({children}) => {
  const classes = useStyles();
  const [results, dispatch] = useResults();
  const [, sDispatch] = useSupport();
  const [flash, setFlash] = React.useState({severity:"notice", message:"", open:false});
  const handleClose = () => {
    setFlash({open:false});
    dispatch(hideResults());
    sDispatch(showSupport(false));
  };
  return (
    <Backdrop
      className={classes.backdrop}
      open={results.show}
    >
      <Container maxWidth="lg" component={Paper} style={{maxHeight:'95%', overflow: 'auto'}}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <SupportButton setFlash={setFlash} />
            <AssertButton setFlash={setFlash} />
            <IconButton onClick={handleClose} aria-label="Close" color="secondary" style={{float:'right' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Flash severity={flash.severity} message={flash.message} open={flash.open} setOpen={setFlash}/>
          </Grid>
          <Grid item xs={12}>
            <SupportForm setFlash={setFlash}/>
          </Grid>
        </Grid>
        {children}
      </Container>
    </Backdrop>
  );
}

const IpResult = ({children, hide, setHide}) => {
  const classes = useStyles();
 
  console.log(hide);
  const handleHide = (e) => {
    setHide(e.target.checked);
  }

  

  return (
    <TableContainer>
      <Table aria-label="ip analysis results" size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{minWidth:20}}></TableCell>
            <TableCell style={{minWidth:20}}></TableCell>
            <TableCell><Typography variant="h6" component="h6">IP Address</Typography></TableCell>
            <TableCell><Typography variant="h6" component="h6">Verdict</Typography></TableCell>
            <TableCell>{(setHide!=null) && <FormControlLabel onChange={handleHide} control={<Switch checked={hide} className={classes.switchBase} />} label="Hide unlisted IPs"/>}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );  
}

export default Results;
